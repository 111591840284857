.contact {
    height: 100vh;
    text-align: center;
    background: #21282c 0% 0% no-repeat padding-box;
    background-size: cover;
    &__container {
        height: 80vh;
        @include flex-center-column;
        justify-content: space-evenly;
        &-info {
            width: 100%;
            height: 10vh;
            @include flex-center-column;
            &-icon {
                width: 2rem;
                height: 2rem;
                @include transition-ease;
                cursor: pointer;
                color: $color-beige;
            }
            &-text {
                margin-top: 0.5rem;
                cursor: pointer;
                font-size: 1rem;
                a {
                    color: $color-beige;
                }
            }
        }
    }
}

@include media-med {
    .contact {
        padding-top: 15vh;
        &__container {
            height: 80vh;
            &-info {
                width: 25vw;
                height: auto;
                @include flex-center-column;
                &-icon {
                    width: 1.5rem;
                    height: 1.5rem;
                }
                &-text {
                    font-size: 0.8rem;
                }
            }
        }
    }
}