.home {
    height: 100vh;
    overflow: hidden;
    text-align: center;
    background: linear-gradient(180deg, rgba(#003049, 0.9), rgba(#000a0f, 0.8)) 0% 0% no-repeat padding-box, url(../../img/home-background.png) no-repeat center;
    background-size: cover;
    &__name {
        height: 80vh;
        margin-top: 10vh;
        @include flex-center-column;
        h1 {
            width: 80vw;
            margin-bottom: 1rem;
            border-bottom: 2px solid $color-yellow;
            padding-bottom: 1rem;
            font-size: 3rem;
        }
        .last-name {
            color: $secondary-color;
        }
    }
    .social-icons {
        width: 100vw;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        a {
            color: #fff;
            @include transition-ease;
        }
        &__info {
            height: 15vh;
            &-icon {
                width: 1.5rem;
                height: 1.5rem;
                transform: translateY(50%);
                @include transition-ease;
                &.open {
                    transform: translateY(0);
                }
            }
            &-title {
                border-top: 1px solid #fff;
                padding-top: 0.4rem;
                visibility: hidden;
                &.open {
                    visibility: visible;
                }
            }
        }
    }
}

@include media-med {
    .home {
        &__name {
            height: 70vh;
            h1 {
                font-weight: 700;
            }
            h3 {
                font-weight: 700;
            }
        }
        .social-icons {
            height: 20vh;
        }
    }
}