@import "config";
@import "menu";
@import "pages/home";
@import "pages/about";
@import "pages/experiences";
@import "pages/contact";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: $font-root;
}

body {
    height: 100vh;
    color: set-text-color($primary-color);
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    background: $primary-color;
}

#root,
.App {
    width: 100%;
    height: 100%;
}

h1,
h2,
h3 {
    font-weight: 400;
}

a {
    text-decoration: none;
}

header {
    position: fixed;
    width: 100%;
    z-index: 2;
}

.section-title {
    padding: 6rem 0 2rem 0;
    color: $color-yellow;
    font-size: 1.7rem;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}

@include media-med {
    html {
        font-size: $font-med;
    }
    .section-title {
        display: none;
    }
}

@include media-large {
    html {
        font-size: $font-large;
    }
    .section-title {
        display: none;
    }
}