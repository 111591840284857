.about {
    align-items: center;
    text-align: center;
    background: #21282c 0% 0% no-repeat padding-box;
    background-size: cover;
    &__container {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
            font-size: 1.2rem;
            font-weight: 700;
            text-shadow: 4px 4px #000;
            color: $color-orange;
        }
        .info {
            margin-top: 2rem;
            position: relative;
            &__images-container {
                display: flex;
                justify-content: space-between;
                &-img {
                    width: 10rem;
                    height: 7.5rem;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                #bio1 {
                    background: url("../../img/bio-img1.jpg") center no-repeat;
                    background-size: cover;
                }
                #bio2 {
                    background: url("../../img/bio-img2.jpg") center no-repeat;
                    background-size: cover;
                }
                #fact1 {
                    background: url("../../img/fact-img1.JPG") center no-repeat;
                    background-size: cover;
                }
                #fact2 {
                    background: url("../../img/fact-img2.jpg") center no-repeat;
                    background-size: cover;
                }
            }
            &__description {
                padding: 0.5rem 0;
                font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                font-size: 1rem;
                line-height: 1.3rem;
                color: $color-beige;
                p {
                    margin: 0.5rem 0;
                }
            }
        }
        .articles {
            margin-top: 2rem;
            &__list {
                width: 100%;
                text-align: center;
                margin: 1rem 0;
                &-item {
                    width: 100%;
                    color: $color-beige;
                    text-decoration: underline;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
        .skills {
            margin-top: 2rem;
            &__container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                &-group {
                    @include flex-center-column;
                    width: 50%;
                    margin: auto;
                    margin-top: 1rem;
                    h5 {
                        margin-bottom: 0.5rem;
                    }
                    &-list {
                        @include flex-center-column;
                        color: $color-beige;
                    }
                }
                #group-4 {
                    width: 100%;
                }
            }
        }
    }
}

@include media-med {
    .about {
        padding-top: 15vh;
        &__container {
            .info {
                &__images-container {
                    justify-content: space-evenly;
                    &-img {
                        width: 12rem;
                        height: 9rem;
                    }
                }
            }
        }
    }
}

@include media-large {
    .about {
        &__container {
            .title {
                margin: 0;
                font-size: 1.5rem;
            }
            .info {
                display: flex;
                &__images-container {
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                    &-img {
                        width: 14rem;
                        height: 10.5rem;
                    }
                }
                &__description {
                    width: 50%;
                    padding: 0;
                    @include flex-center-column;
                }
            }
            .skills {
                &__container {
                    &-group {
                        width: 33%;
                    }
                    #group-3,
                    #group-4 {
                        width: 50%;
                    }
                }
            }
        }
    }
}