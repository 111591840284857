.experiences {
    padding-bottom: 2rem;
    background: #21282c 0% 0% no-repeat padding-box;
    background-size: cover;
    @include flex-center-column;
    &__container {
        position: relative;
        margin-top: 2rem;
        padding: 1rem 0;
        border-radius: 35px;
        background: #142027 0% 0% no-repeat padding-box;
        box-shadow: 2px 7px 2px #000000;
        h2 {
            text-align: center;
            color: $color-beige;
        }
        .project {
            font-size: 0.8rem;
            padding: 1rem;
            padding-bottom: 0;
            text-align: left;
            &__card {
                margin-bottom: 1rem;
            }
            &__headtext {
                font-size: 1rem;
                color: $color-orange;
                margin: 0.2rem 0;
            }
            &__time {
                margin: 0.2rem 0;
                font-size: 0.7rem;
                color: $color-yellow;
            }
            &__description {
                .project-detail {
                    margin: 0.5rem 0;
                    margin-left: 1rem;
                    margin-bottom: 2.5rem;
                }
                h5 {
                    font-size: 1rem;
                    margin: 0.2rem 0;
                }
                h6 {
                    font-size: 0.65rem;
                    margin: 0.2rem 0;
                }
                p {
                    margin: 0.2rem 0;
                    margin-left: 1rem;
                    color: $color-beige;
                    font-weight: 100;
                    line-height: 1rem;
                }
                ul {
                    margin: 0.2rem 0;
                    margin-left: 2rem;
                    color: $color-beige;
                    font-weight: 100;
                    line-height: 1rem;
                }
            }
        }
    }
}

@include media-med {
    .experiences {
        padding-top: 15vh;
    }
}