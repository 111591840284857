.menu-btn {
    position: absolute;
    display: block;
    top: 3rem;
    right: 2rem;
    height: 20px;
    width: 30px;
    cursor: pointer;
    z-index: 1;
    @include transition-ease;
    &__burger {
        position: absolute;
        top: 0.5rem;
        right: 0;
        width: 30px;
        height: 2px;
        background: set-text-color($primary-color);
        @include transition-ease;
        &::before {
            content: "";
            position: absolute;
            top: -10px;
            width: 30px;
            height: 2px;
            background: set-text-color($primary-color);
            @include transition-ease;
        }
        &::after {
            content: "";
            position: absolute;
            top: 10px;
            width: 22px;
            height: 2px;
            background: set-text-color($primary-color);
            @include transition-ease;
        }
        &.open {
            transform: rotate(720deg);
            background: transparent;
            &::before {
                transform: rotate(45deg) translate(4px, 10px);
            }
            &::after {
                width: 30px;
                transform: rotate(-45deg) translate(4px, -10px);
            }
        }
    }
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    opacity: 0.98;
    visibility: hidden;
    &.open {
        visibility: visible;
    }
    .menu-nav {
        height: 100vh;
        @include flex-center-column;
        list-style: none;
        transform: translateY(-100%);
        background: #21282c 0% 0% no-repeat padding-box;
        @include transition-ease;
        &.open {
            transform: translateY(0);
        }
        &__item {
            transform: translateX(100vh);
            cursor: pointer;
            @include transition-ease;
            &.open {
                transform: translateX(0);
            }
        }
        &__link {
            padding: 2rem 0;
            display: inline-block;
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: 400;
            @include transition-ease;
            &.active {
                color: $color-yellow;
            }
        }
    }
}

@for $i from 1 through 5 {
    .menu-nav__item:nth-child(#{$i}) {
        transition-delay: ($i * 0.1s) + 0.15s;
    }
}

@include media-med {
    .menu-btn {
        display: none;
    }
    .nav {
        visibility: visible;
        .menu-nav {
            height: 10vh;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            transform: none;
            background: transparent;
            &__item {
                transform: none;
                padding-right: 1.5rem;
            }
            &__link {
                padding: 0;
                font-size: 1.2rem;
            }
        }
    }
}